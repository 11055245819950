import React from "react"
import { graphql } from "gatsby"
import PageLayout from '../layouts/pageLayout';
import { Helmet } from 'react-helmet';

export default ({ data }) => {
  const markdown = data.markdownRemark;
  return (
    <PageLayout>
      <Helmet>
        <title>Iteem - {markdown.frontmatter.title}</title>
        <meta name="description" content={markdown.excerpt}/>
      </Helmet>
      <nav className="breadcrumb is-centered" aria-label="breadcrumbs">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/projects">Projects</a></li>
          <li className="is-active"><a href="./" aria-current="page">{markdown.frontmatter.title}</a></li>
        </ul>
      </nav>
      <h1 className="title is-1">{markdown.frontmatter.title}</h1>
      <div className="content" dangerouslySetInnerHTML={{ __html: markdown.html }} />
    </PageLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
      }
    }
  }
`;
